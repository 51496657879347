import React from "react";
import { Modal } from "react-bootstrap";
import "../style/modalStopper.css";
import ArrowDown from "../images/arrow-down-space.svg";
import { useMediaQuery } from "../../../hooks/useMediaQuery";

export const ModalStopperComponent = ({
  showModalStopper,
  setShowModalStopper,
  setSelectedOptions,
  setNumber,
  setIsGnc,
  setShowDropdownKms,
  isMobile,
  handleButtonClick,
}) => {
  const handleClose = () => setShowModalStopper(false);
  let isPageWide = useMediaQuery("(min-width: 992px)");
  let isPageSmall = useMediaQuery("(min-width: 445px)");

  const handleCloseError = () => {
    setSelectedOptions({
      year: null,
      brand: null,
      model: null,
      version: null,
      kms: null,
    });
    setNumber("");
    setIsGnc("");
    setShowDropdownKms(false);
    if (isMobile) {
      handleButtonClick(1);
    }
    setShowModalStopper(false);
  };

  return (
    <Modal show={showModalStopper} onHide={handleClose} size="lg" style={{ display: "flex", alignItems: "center" }} centered>
      <Modal.Body className="modalStopper">
      <div className="background-custom-modal-stopper" /* style={{ bottom: isPageWide ? "-20px" : "-15px", height: isPageWide ? "40%" : "45%" }} */ />
        <div>
          <p className="title-modal-stopper mt-3">¡Ups!</p>
          <img src={ArrowDown} alt="arrow-down" className="arrow-down mb-2"/>
          <p className="description-modal-stopper mt-2">
            Lamentablemente no podemos hacer una oferta de {isPageWide && <br />}
            compra por tu auto. Agradecemos tu interés y {isPageWide && <br />}
            quedamos a disposición para cualquier consulta futura.
          </p>
        </div>
        <div className="d-flex align-items-center justify-content-center flex-column" style={{ zIndex: "1" }}>
          <p className="text-footer-modal-stopper mb-3">
            ¡Que tengas un buen dia!
          </p>
          <button
            className="btn-modal-stopper"
            onClick={handleCloseError}
          >
            Volver al inicio
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ModalStopperComponent;
